/**
 * Functions related to creating, editing, and deleting custom menus.
 */

jQuery( function( $ ) {

	var google_tracking   = typeof ga === 'function',
	    rollbar_tracking  = typeof Rollbar === 'function',
	    intercom_tracking = typeof Intercom === 'function';

	var max_menus   = 12,
	    max_recipes = 15;

	/**
	 * Adds the 'focus' class to create a custom menu forms when the input is focused
	 */
	$( '.js_addtomenu__input' ).on( 'focusin', function( e ) {
		$( this ).parent( '.js_addtomenu__form' ).addClass( 'focus' );
		$( this ).parent( 'form' ).parent( '.js_addtomenu__form' ).addClass( 'focus' );
	} );

	$( '.js_addtomenu__input' ).on( 'focusout', function( e ) {
		$( this ).parent( '.js_addtomenu__form' ).removeClass( 'focus' );
		$( this ).parent( 'form' ).parent( '.js_addtomenu__form' ).removeClass( 'focus' );
	} );

	$( '.js_create-menu__input' ).on( 'focusin', function( e ) {
		$( this ).parent( '.js_create-menu__form' ).addClass( 'focus' );
		$( this ).parent( 'form' ).parent( '.js_create-menu__form' ).addClass( 'focus' );
	} );

	$( '.js_create-menu__input' ).on( 'focusout', function( e ) {
		$( this ).parent( '.js_create-menu__form' ).removeClass( 'focus' );
		$( this ).parent( 'form' ).parent( '.js_create-menu__form' ).removeClass( 'focus' );
	} );

	// Hide the onboarding tour when the Create A New Menu button is clicked
	$( document ).on( 'click', '.js_create-menu', function( e ) {
		if ( $( '.tour-container' ).length ) {
			$( '.tour-container' ).remove();
		}
	} );

	$( document ).on( 'click', '.js_addtomenu__btn', function( e ) {
		e.preventDefault();

		var recipe    = $( '.js_addtomenu__input' ).attr( 'data-recipe' ),
		    menu_name = sanitize_string( $( '.js_addtomenu__input' ).val() );

		if ( ! menu_name ) {
			oamm_show_notification( 'Please enter a name for your new meal plan.', 'error' );
			return;
		}

		oamm_show_notification( 'Building your new meal plan...' );
		$( this ).attr( 'disabled', true );

		var req_args = {
			'url': oamm_api.url + 'custom_menus',
			'method': 'POST',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
			'data': {
				'name': menu_name,
				'recipe_ids': [
					recipe
				],
			},
			'dataType': 'json',
		};

		$( '.submenu' ).addClass( 'loading' );

		// Make the request
		$.ajax( req_args )
			.always( function() {
				$( '.submenu' ).removeClass( 'loading' );
			} )
			.done( function( response ) {
				$( '.dropdown-open' ).removeClass( 'dropdown-open' );
				$( 'li.js_addtomenu__form' ).before( '<li><a href="#" class="js_addtomenu__menu" data-found="1" data-recipe="' + recipe + '" data-menu="' + response.id + '" data-recipe-count="1">' + response.name + '</a></li>' );

				var total_menus = $( '.js_addtomenu__menu' ).length;

				if ( total_menus >= max_menus ) {
					$( '.js_addtomenu__form' ).remove();
				}

				oamm_show_notification( 'Success! We\'ve set up your meal plan. We\'re now redirecting you to your new meal plan.' );

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Create', 'From Recipe' );
				}

				if ( intercom_tracking ) {
					var intercom_data = {
						'source': 'recipe',
					};

					Intercom( 'trackEvent', 'Custom Menu: Create', intercom_data );
				}

				window.location = oamm_api.site + '/custom-meal-plans/' + response.id + '/';
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				oamm_show_notification( 'There was an error creating your new meal plan and adding a recipe.', 'error' );

				$( this ).attr( 'disabled', false );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error creating your new meal plan and adding a recipe.', response );
				}
			} );
	} );

	/**
	 * Delete a custom menu.
	 */
	$( document ).on( 'click', '.js_menu-trash-yes', function( e ) {
		e.preventDefault();

		var $this       = $( this ),
		    menu        = parseInt( $this.attr( 'data-menu' ) ),
		    redirect    = parseInt( $this.attr( 'data-redirect' ) ),
		    menus_count = parseInt( $this.attr( 'data-menus-count' ) ),
		    menu_slots  = parseInt( $this.attr( 'data-menu-slots' ) );

		var confirm = window.confirm( 'Are you sure you want to delete this meal plan?' );

		if ( ! confirm ) {
			return;
		}

		oamm_show_notification( 'Deleting meal plan...', 'error' );

		var req_args = {
			'url': oamm_api.url + 'custom_menus/' + menu,
			'method': 'DELETE',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
		};

		if ( $this.hasClass( 'btn' ) ) {
			$this.html( '<i class="fas fa-spinner fa-pulse"></i> Deleting...' );
		}

		// Make the request
		$.ajax( req_args )
			.always( function() {
				$( '.notification-bar' ).remove();
				$( 'body' ).append( '<div class="notification-bar"></div>' );
				$( '.notification-bar' ).delay( 3000 ).fadeOut( 500 );
			} )
			.done( function( response ) {
				$( 'tr#' + menu ).remove();

				// Decrease the current menus count
				$( '.js_menu-trash-yes' ).attr( 'data-menus-count', menus_count - 1 );

				// Make sure the ability to create a new menu in this deleted slot is disabled if they are at or over their menu slot limit
				if ( menus_count <= menu_slots ) {
					$( '.mb-menus-list-item[data-menu="' + menu + '"]' ).replaceWith( '<li class="mb-menus-list-item empty-menu-slot js_show-new-menu-form"><a href="javscript:void(0);" onclick="ga( \'send\', \'event\', \'Custom Menu\', \'Click\', \'Menus List: Start a new meal plan\' );"><i class="fas fa-plus" aria-hidden="true"></i> Start a new meal plan</a></li>' );
				} else {
					$( '.mb-menus-list-item[data-menu="' + menu + '"]' ).remove();
				}

				$( '.notification-bar' ).addClass( 'error' );
				$( '.notification-bar' ).text( 'This meal plan has been deleted.' );

				if ( $this.hasClass( 'btn' ) ) {
					$this.html( '<i class="fas fa-spinner fa-pulse"></i> Redirecting...' );
				}

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Delete' );
				}

				if ( intercom_tracking ) {
					Intercom( 'trackEvent', 'Custom Menu: Delete' );
				}

				if ( 1 === redirect ) {
					window.location = oamm_api.site + '/custom-meal-plans/';
				}

				if ( $( '.js_create-menu__form' ).length ) {
					$( '.js_create-menu__form' ).show();
					$( '.js_create-menu__notice' ).hide();
				}
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				$( '.notification-bar' ).addClass( 'error' );
				$( '.notification-bar' ).text( 'There was an error deleting this meal plan.' );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error trying to delete a custom meal plan.', response );
				}

				if ( $this.hasClass( 'btn' ) ) {
					$this.html( '<i class="fas fa-trash"> Delete Meal Plan' );
				}
			} );
	} );

	/**
	 * Copy an existing menu.
	 */
	$( document ).on( 'click', '.js_copy-menu', function( e ) {
		e.preventDefault();

		var $this = $( this );

		if ( $this.hasClass( 'btn' ) ) {
			$this.html( '<i class="fas fa-spinner fa-pulse"></i> Adding to your meal plans...' );
		}

		var recipes = JSON.parse( $this.attr( 'data-recipes' ) ),
		    name    = $this.attr( 'data-name' );

		if ( ! name ) {
			oamm_show_notification( 'You need to give this new meal plan a name.', 'error' );

			if ( $this.hasClass( 'btn' ) ) {
				$this.html( 'Add to My Meal Plans' );
			}

			return;
		}

		$this.addClass( 'disabled' );

		var req_args = {
			'url': oamm_api.url + 'custom_menus',
			'method': 'POST',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
			'data': {
				'name': name,
				'recipe_ids': recipes,
			},
			'dataType': 'json',
		};

		// Make the request
		$.ajax( req_args )
			.done( function( response ) {
				$this.html( '<i class="fas fa-spinner fa-pulse"></i> Redirecting...' );

				oamm_show_notification( 'This meal plan has been successfully added to your meal plans. We are now redirecting you to the new meal plan.' );

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Create', 'Copy' );
				}

				window.location = oamm_api.site + '/custom-meal-plans/' + response.id + '/';
			} )
			.fail( function( response ) {
				if ( $this.hasClass( 'btn' ) ) {
					$this.html( 'Add to My Meal Plans' );
				}

				if ( response && response.responseJSON && response.responseJSON.errors && response.responseJSON.errors.base[0] ) {
					oamm_show_notification( title_case( response.responseJSON.errors.base[0] ), 'error' );

					if ( rollbar_tracking ) {
						Rollbar.error( title_case( response.responseJSON.errors.base[0] ), req_args );
					}
				} else {
					console.log( req_args );
					console.log( response );

					oamm_show_notification( 'There was an error adding this meal plan to your meal plans.', 'error' );

					if ( rollbar_tracking ) {
						Rollbar.error( 'There was an error adding this meal plan to your meal plans.', response );
					}
				}

				$this.removeClass( 'disabled' );
			} );
	} );

	/**
	 * Create a new custom menu.
	 */
	$( document ).on( 'click', '.js_create-menu__btn', function( e ) {
		e.preventDefault();

		var $this       = $( this ),
		    menu_name   = sanitize_string( $this.siblings( '.js_create-menu__input' ).val() ),
		    fav_recipes = [],
				recipes_limit = parseInt( $this.attr( 'max-recipes' ) ) || max_recipes;

		// If there's no menu name set
		if ( ! menu_name ) {
			oamm_show_notification( 'Please enter a name for your new meal plan.', 'error' );
			return;
		}

		// Add the selected favorites if we're on the Favorites page
		if ( $( '.mb-recipes-list__favorites' ).length ) {
			$.each( $( '.list input[type="checkbox"]:checked' ), function() {
				fav_recipes.push( $( this ).val() );
			} );
		}

		// If you've selected too many favorites
		if ( fav_recipes.length > recipes_limit ) {
			oamm_show_notification( 'You\'ve selected too many recipes. Meal plans can only have up to ' + recipes_limit + ' recipes.', 'error' );
			return;
		}

		var req_args = {
			'url': oamm_api.url + 'custom_menus',
			'method': 'POST',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
			'data': {
				'name': menu_name,
				'recipe_ids': fav_recipes,
			},
			'dataType': 'json',
		};

		$( '.submenu' ).addClass( 'loading' );

		// Make the request
		$.ajax( req_args )
			.always( function() {
				$( '.submenu' ).removeClass( 'loading' );
			} )
			.done( function( response ) {
				$( '.js_create-menu__input' ).val( '' );
				$( '.dropdown-open' ).removeClass( 'dropdown-open' );
				$( '.js_create-menu' ).html( '<i class="fas fa-spinner fa-pulse"></i> Redirecting...' );
				oamm_show_notification( 'This meal plan has been successfully added to your meal plans. We are now redirecting you to your new meal plan.' );

				if ( google_tracking ) {
					if ( fav_recipes.length ) {
						ga( 'send', 'event', 'Custom Menu', 'Create', 'Favorites' );
					} else {
						ga( 'send', 'event', 'Custom Menu', 'Create', 'New' );
					}
				}

				if ( intercom_tracking ) {
					var intercom_data = {};

					if ( fav_recipes.length ) {
						intercom_data = {
							'source': 'favorites',
						};
					} else {
						intercom_data = {
							'source': 'new',
						};
					}

					Intercom( 'trackEvent', 'Custom Menu: Create', intercom_data );
				}

				window.location = oamm_api.site + '/custom-meal-plans/' + response.id + '/';
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				oamm_show_notification( 'There was an error creating your new meal plan.', 'error' );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error creating your new meal plan.', response );
				}
			} );
	} );

	/**
	 * Removing a recipe from a menu.
	 */
	$( document ).on( 'click', '.js_delete-recipe', function( e ) {
		e.preventDefault();

		var $this  = $( this ),
		    recipe = $this.attr( 'data-recipe' ),
		    menu   = $this.attr( 'data-menu' ),
		    type   = $this.attr( 'data-type' ),
		    count  = $( '.js_delete-recipe[data-type="' + type + '"]' ).length;

		$this.attr( 'disabled', true );
		oamm_show_notification( 'Deleting recipe...', 'error' );

		var req_args = {
			'url': oamm_api.url + 'custom_menus/' + menu + '/recipes?op=remove&value=' + recipe,
			'method': 'PUT',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
		};

		// Make the request
		$.ajax( req_args )
			.done( function( response ) {
				oamm_show_notification( 'Recipe deleted.', 'error' );

				$( 'li#recipe-' + recipe ).fadeOut( 250, function() {
					var new_count = count - 1;

					if ( 0 === new_count ) {
						$( '.mb-numbers-item.' + type ).remove();
					} else {
						$( '.mb-numbers-item.' + type + ' .mb-numbers-item-number' ).text( new_count );
					}

					$( this ).remove();
				} );

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Delete Recipe', recipe );
				}

				if ( intercom_tracking ) {
					Intercom( 'trackEvent', 'Custom Menu: Delete Recipe' );
				}
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				oamm_show_notification( 'There was an error deleting this recipe from your meal plan.', 'error' );
				$this.attr( 'disabled', false );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error deleting this recipe from your meal plan.', response );
				}
			} );
	} );

	/**
	 * Editing a custom menus title
	 */
	$( document ).on( 'click', '.js_edit-title', function( e ) {
		e.preventDefault();

		// Hide the title and the edit button
		$( '.mb-hero-title-text, .mb-stats-bar-title' ).hide();
		$( this ).hide();

		// Show the edit form
		$( '.mb-hero-edit, .mb-stats-bar-edit' ).show();
		resize_textarea( $( '.mb-hero-edit-textarea' ) );
		resize_textarea( $( '.mb-stats-bar-edit-textarea' ) );
		$( '.mb-hero-edit-textarea' ).focus();
	} );

	$( '.mb-hero-edit-textarea, .mb-stats-bar-edit-textarea' ).on( 'paste input', function() {
		resize_textarea( $( this ) );
	} );

	function oamm_update_menu_name( $form, $textarea ) {
		$form.addClass( 'loading' );

		var menu_name = sanitize_string( $textarea.val().replace( /\n/g, ' ' ) ),
		    menu_id   = $form.attr( 'data-menu-id' );

		if ( 0 === menu_name.trim().length ) {
			oamm_show_notification( 'Please give your meal plan a title.', 'error' );
			$form.removeClass( 'loading' );
			return;
		}

		oamm_show_notification( 'Updating...' );

		var req_args = {
			'url': oamm_api.url + 'custom_menus/' + menu_id + '/',
			'method': 'PUT',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
			'data': {
				'name': menu_name,
			},
			'dataType': 'json',
		};

		// Make the request
		$.ajax( req_args )
			.done( function( response ) {
				$form.removeClass( 'loading' );
				oamm_show_notification( 'Updated meal plan title' );

				$( '.mb-hero-edit, .mb-stats-bar-edit' ).hide();
				$( '.mb-hero-title-text, .mb-stats-bar-title' ).html( response.name ).show();
				$( '.mb-hero-edit-textarea, .mb-stats-bar-edit-textarea' ).val( response.name );
				$( '.mb-stats-bar-title' ).append( '<a href="#" class="mb-stats-bar-title-edit-button js_edit-title"><i class="fa fa-pencil-square-o" aria-hidden="true"></i> Edit</a>' );
				$( '.js_edit-title' ).show();

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Updated', 'Name' );
				}
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				oamm_show_notification( 'There was an error editing your meal plan\'s title.', 'error' );

				$form.removeClass( 'loading' );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error editing your meal plan\'s title.', response );
				}
			} );
	}

	$( '.mb-hero-edit' ).on( 'submit', function( e ) {
		e.preventDefault();
		oamm_update_menu_name( $( '.mb-hero-edit' ), $( '.mb-hero-edit-textarea' ) );
	} );

	$( '.mb-stats-bar-edit' ).on( 'submit', function( e ) {
		e.preventDefault();
		oamm_update_menu_name( $( '.mb-stats-bar-edit' ), $( '.mb-stats-bar-edit-textarea' ) );
	} );

	$( document ).on( 'click', '.js_btn-title-cancel', function() {
		$( '.mb-hero-edit, .mb-stats-bar-edit' ).hide();
		$( '.mb-hero-title-text, .mb-stats-bar-title, .js_edit-title' ).show();
	} );

	/**
	 * Editing a custom menus description
	 */
	$( document ).on( 'click', '.js_edit-description', function( e ) {
		e.preventDefault();

		// Hide the original text and edit button.
		$( this ).hide();
		$( '.sub-title-text' ).hide();

		// Show the edit form.
		$( '.sub-title-edit' ).show();
		resize_textarea( $( '.sub-title-edit-textarea' ) );
		$( '.sub-title-edit-textarea' ).focus();
	} );

	$( '.sub-title-edit-textarea' ).on( 'paste input', function() {
		resize_textarea( $( this ) );
	} );

	$( '.sub-title-edit' ).on( 'submit', function( e ) {
		e.preventDefault();

		$( '.sub-title-edit' ).addClass( 'loading' );

		var menu_desc = sanitize_string( $( '.sub-title-edit-textarea' ).val().replace( /\n/g, '<br>' ) ),
		    menu_id   = $( this ).attr( 'data-menu-id' );

		var req_args = {
			'url': oamm_api.url + 'custom_menus/' + menu_id + '/',
			'method': 'PUT',
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + oamm_api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
			'data': {
				'description': menu_desc,
			},
			'dataType': 'json',
		};

		// Make the request
		$.ajax( req_args )
			.done( function( response ) {
				$( '.sub-title-edit' ).removeClass( 'loading' );

				oamm_show_notification( 'Updated meal plan description' );

				$( '.sub-title-edit' ).hide();
				$( '.sub-title-text' ).show().html( '' );

				if ( response.description ) {
					$( '.sub-title-text' ).html( response.description.replace( /\n/g, '<br>' ) );
				}

				$( '.js_edit-description' ).show();
				$( '.js_edit-description-text' ).text( 'Edit' );

				if ( google_tracking ) {
					ga( 'send', 'event', 'Custom Menu', 'Updated', 'Description' );
				}
			} )
			.fail( function( response ) {
				console.log( req_args );
				console.log( response );

				$( '.sub-title-edit' ).removeClass( 'loading' );

				oamm_show_notification( 'There was an error editing your meal plans description.', 'error' );

				if ( rollbar_tracking ) {
					Rollbar.error( 'There was an error editing your meal plans description.', response );
				}
			} );
	} );

	$( document ).on( 'click', '.js_btn-desc-cancel', function() {
		$( '.sub-title-edit' ).hide();
		$( '.sub-title-text' ).show();
		$( '.js_edit-description' ).show();
	} );

	$( document ).on( 'click', '.js_add-to-a-menu', function( e ) {
		e.preventDefault();

		$( '.mb-menus-list' ).show();
	} );

	$( document ).on( 'click', '.mb-menus-list-close', function( e ) {
		e.preventDefault();

		$( '.mb-menus-list' ).hide();
	} );

	$( document ).on( 'click', '.js_show-new-menu-form', function( e ) {
		e.preventDefault();

		if ( $( this ).attr( 'data-menu-count' ) >= max_menus ) {
			oamm_show_notification( 'You already have ' + max_menus + ' meal plans.' );
			return;
		}

		$( '.mb-new-menu' ).show();
	} );

	$( document ).on( 'click', '.mb-new-menu-close', function( e ) {
		e.preventDefault();

		$( '.mb-new-menu' ).hide();
	} );

	$( document ).on( 'click', '.mb-menus-list__custom-menus .mb-menus-list-item:not(.empty-menu-slot)', function( e ) {
		if ( e.target.className === 'mb-menus-list-item-delete' || e.target.className === 'fas fa-trash' || e.target.className === 'mb-menus-list-item-name' ) {
			return;
		}

		var link = $( this ).find( '.mb-menus-list-item-name' ).attr( 'href' );
		window.location = link;
	} );


	/**
	 * Helper function to title the first letter in a string.
	 */
	function title_case( string ) {
	    return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
	} // title_case()

	/**
	 * Helpfer function to sanitize a string input
	 */
	function sanitize_string( html ) {

		html = html.replace( /<br>/g, '.br.' ); // Hack to keep the line breaks
		var tmp = document.createElement( 'div' );
		tmp.innerHTML = html;
		var sanitized_string = tmp.textContent || tmp.innerText || '';
		sanitized_string = sanitized_string.replace( /.br./g, '\n' ); // Hack to keep the line breaks
		return sanitized_string;

	} // sanitize_string()

	/**
	 * Helper function to expand and shrink a textarea
	 */
	function resize_textarea( $textarea ) {
	 	if ( $textarea.outerHeight() > $textarea.prop( 'scrollHeight' ) ) {
	 	    $textarea.height( 1 );
	 	}

	 	while ( $textarea.outerHeight() < $textarea.prop( 'scrollHeight' ) + parseFloat( $textarea.css( 'borderTopWidth' ) ) + parseFloat( $textarea.css( 'borderBottomWidth' ) ) ) {
	 	    $textarea.height( $textarea.height() + 1 );
	 	}
	 } // resize_textarea()

} );
