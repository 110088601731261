jQuery( function( $ ) {

	/**
	 * Hides the lightbox when clicked
	 */
	$( document ).on( 'click', '.oamm-lightbox', function( e ) {
		if ( e.target !== this ) {
			return;
		}

		$( this ).hide();
	} );

	$( document ).on( 'click', '.oamm-lightbox-close', function( e ) {
		e.preventDefault();

		$( '.oamm-lightbox, .oamm-lightbox .oamm-lightbox-content' ).hide();
	} );

	// Close the lightbox when the esc key is pressed
	$( document ).keyup( function( e ) {
	    if ( 27 === e.which && ( $( '.oamm-lightbox' ).is( ':visible' ) || $( '.mb-new-menu' ).is( ':visible' ) ) ) {
	        $( '.oamm-lightbox, .oamm-lightbox .oamm-lightbox-content, .mb-new-menu' ).hide();
	    }
	} );

} );
