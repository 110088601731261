jQuery( function( $ ) {

	$( '.oamm-cookies-close' ).on( 'click', function( e ) {

		e.preventDefault();

		$( '.oamm-cookies' ).remove();

		var now  = new Date(),
		    time = now.getTime();

		time += 1000 * 3600 * 24 * 365; // 1 second * 1 hour * 1 day * 365 days
		now.setTime( time );

		document.cookie = 'oamm_cookies=close; path=/; expires=' + now.toUTCString();

	} );

} );
