/**
 * Uses Slick to set up the menu and recipe card sliders.
 *
 * @link https://kenwheeler.github.io/slick/
 */

jQuery( function( $ ) {

	// Check that there is a slider on the page.
	if ( $( '.slider' ).length ) {
		// Make the slider visible if it was lazy loaded.
		$( '.slider' ).show();

		$( '.slider' ).slick( {
			slidesToShow: 1,
			mobileFirst: true,
			prevArrow: '<a href="#" class="slick-prev"><i class="fas fa-angle-left"></i></a>',
			nextArrow: '<a href="#" class="slick-next"><i class="fas fa-angle-right"></i></a>',
			autoplay: true,
			autoplaySpeed: 6000,
			pauseOnHover: true,
			slidesToScroll: 1,
			responsive: [ {
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			}, {
				breakpoint: 960,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			} ]
		} );
	}

	// This is for the slider used in content blocks on blog partner and affiliate pages.
	if ( $( '.block-slider' ).length ) {
		$( '.block-slider' ).slick( {
			arrows: false,
			dots: true,
			autoplay: true,
			autoplaySpeed: 7000,
			adaptiveHeight: true
		} );
	}

	// This is for the top slider used on the Videos archive page.
	if ( $( '.oamm-videos-carousel' ).length ) {
		$( '.oamm-videos-carousel' ).slick( {
			centerMode: true,
			autoplay: true,
			autoplaySpeed: 10000,
			adaptiveHeight: true,
			slidesToShow: 1,
			infinite: true,
			centerPadding: '15%',
			prevArrow: '<a href="#" class="slick-prev"><i class="fas fa-angle-left"></i></a>',
			nextArrow: '<a href="#" class="slick-next"><i class="fas fa-angle-right"></i></a>',
		} );
	}

	// This is for the category slider used on the Videos archive page.
	if ( $( '.oamm-videos-row-carousel' ).length ) {
		$( '.oamm-videos-row-carousel' ).slick( {
			adaptiveHeight: true,
			slidesToShow: 6,
			slidesToScroll: 6,
			infinite: true,
			prevArrow: '',
			nextArrow: '<a href="#" class="slick-next"><i class="fas fa-angle-right"></i></a>',
			responsive: [ {
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2
				}
			}, {
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3
				}
			}, {
				breakpoint: 960,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 4
				}
			}, {
				breakpoint: 1100,
				settings: {
					slidesToShow: 5,
					slidesToScroll: 5
				}
			} ]
		} );
	}

} );
