jQuery( function( $ ) {

	function oamm_get_favicon() {

		if ( 0 === $( '.js_get-favicon' ).length ) {
			return;
		}

		var site   = oamm_get_api().site;
		var domain = $( '.js_get-favicon' ).attr( 'data-domain' );

		if ( ! domain || domain === '' ) {
			return;
		}

		var req_args = {
			'url'         : site + '/services/favicon.php?domain=' + domain,
			'method'      : 'GET',
			'crossDomain' : true,
			'xhrFields'   : {
				'withCredentials': true,
			},
		};

		$.ajax( req_args )
			.done( function( result ) {
				if ( result ) {
					$( '.js_get-favicon' ).attr( 'src', result );
				}
			} );

	} // oamm_get_favicon()

	oamm_get_favicon();

} );
