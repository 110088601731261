jQuery( function( $ ) {

	$( '.sub-title-more a' ).on( 'click', function( e ) {

		e.preventDefault();
		$( this ).parent().prev().removeClass( 'sub-title-hidden' );
		$( this ).remove();

	} );

} );
