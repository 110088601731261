jQuery( function( $ ) {

	/**
	 * Adds the 'focus' class to the search form on focus.
	 */
	function oamm_search_focus() {
		$( '.hero-search-input, .mb-search-form-input' ).on( 'focusin', function( e ) {
			$( this ).parent( 'form' ).addClass( 'focus' );
		} );

		$( '.hero-search-input, .mb-search-form-input' ).on( 'focusout', function( e ) {
			$( this ).parent( 'form' ).removeClass( 'focus' );
		} );
	} // oamm_search_focus()

	oamm_search_focus();

} );
