/**
 * This shows the navigation menu on mobile when the menu icon is clicked.
 */

jQuery( function( $ ) {

	var docElem = window.document.documentElement,
		support = Modernizr.csstransitions,
		transEndEventNames = {
			'WebkitTransition': 'webkitTransitionEnd',
			'MozTransition': 'transitionend',
			'OTransition': 'oTransitionEnd',
			'msTransition': 'MSTransitionEnd',
			'transition': 'transitionend'
		},
		transEndEventName = transEndEventNames[ Modernizr.prefixed( 'transition' ) ],
		docscroll = 0;

	function scrollY() {
		return window.pageYOffset || docElem.scrollTop;
	}

	/**
	 * Sets up the on click events for opening and closing the menu.
	 */
	function oamm_mobile_menu() {
		var $perspective = $( '#mobile-perspective' ),
			$wrapper     = $( '.mobile-wrapper' );

		$( '.js_mobile-menu' ).on( 'click', function( e ) {
			e.stopPropagation();
			e.preventDefault();

			if ( typeof ga === 'function' ) {
				ga( 'send', 'event', 'Mobile Nav', 'Open' );
			}

			docscroll = scrollY();
			// Change top of wrapper
			$wrapper.css( 'top', docscroll * -1 + 'px' );
			// Mac chrome issue:
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			// Add modalview class
			$perspective.addClass( 'modalview' );
			// Animate...
			setTimeout( function() {
				$perspective.addClass( 'animate' );
			}, 25 );
		} );

		$( '.mobile-container' ).on( 'click', function( ev ) {
			if ( $perspective.hasClass( 'animate' ) ) {
				var onEndTransFn = function( ev ) {
					if ( support && ( ev.target.className !== 'container' || ev.propertyName.indexOf( 'transform' ) == -1 ) ) return;
					this.removeEventListener( transEndEventName, onEndTransFn );
					$perspective.removeClass( 'modalview' );
					// Mac chrome issue:
					document.body.scrollTop = document.documentElement.scrollTop = docscroll;
					// Change top of wrapper
					$wrapper.css( 'top', '0' );
				};

				if ( typeof ga === 'function' ) {
					ga( 'send', 'event', 'Mobile Nav', 'Close' );
				}

				if ( support ) {
					$perspective.on( transEndEventName, onEndTransFn );
				} else {
					onEndTransFn.call();
				}

				$perspective.removeClass( 'animate' );

				setTimeout( function() {
					$perspective.removeClass( 'modalview' );
					document.body.scrollTop = document.documentElement.scrollTop = docscroll;
					$wrapper.css( 'top', '0' );
				}, 500 );
			}
		} );
	} // oamm_mobile_menu()

	oamm_mobile_menu();

} );
