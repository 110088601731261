function oamm_get_api() {

	// Get the current environment via the domain ending (Ex: dev, net, com)
	var domain = window.location.hostname.split( '.' );
	var env    = domain[ domain.length - 1 ];

	// Set defaults
	var api_url = 'http://api.oamm.loc:3000/v2/';
	var token   = 'token';
	var site    = 'http://oamm.loc';

	if ( env === 'menu' ) {
		api_url = 'https://api.freezer.menu/v2/';
		token   = 'testalthoughthewordtestmaynotbelongenoughforatoken';
		site    = 'https://freezer.menu';
	} else if ( env === 'com' ) {
		api_url = 'https://api.onceamonthmeals.com/v2/';
		token   = '315db9e1989e83c7687985a3ed8ebb41fe9289efe8344d0f9c6f7ff9b6724e32021b56536fb415e2d507001c3f45c9e2faa6aafff363ddb27a0693ba9f7856a5';
		site    = 'https://onceamonthmeals.com';
	}

	return {
		'url'   : api_url,
		'token' : token,
		'site'  : site,
	};

} // oamm_get_api()

var oamm_api = oamm_get_api();

/**
 * Get viewport dimensions that match media queries.
 */
function oamm_viewport() {
	var e = window,
		a = 'inner';

	if ( ! ( 'innerWidth' in window ) ) {
		a = 'client';
		e = document.documentElement || document.body;
	}

	return {
		width  : e[ a + 'Width' ],
		height : e[ a + 'Height' ],
	};
} // oamm_viewport()

 /**
  * Shows a notification message.
  */
function oamm_show_notification( message, type ) {

	if ( ! message ) {
		return;
	}

	if ( ! type ) {
		type = 'success';
	}

	var delay = 10000;

	if ( 'error' === type ) {
		delay = 5000;
	}

	jQuery( '.notification-bar' ).remove();
	jQuery( 'body' ).append( '<div class="notification-bar"></div>' );
	jQuery( '.notification-bar' ).addClass( type );
	jQuery( '.notification-bar' ).html( message );
	jQuery( '.notification-bar' ).delay( delay ).fadeOut( 500 );

} // oamm_show_notification()

function oamm_get_user_preferences( user ) {

	if ( ! user ) {
		return false;
	}

	var pref_args = {
		'async'       : true,
		'crossDomain' : true,
		'url'         : oamm_api.url + 'users/' + user + '/preferences',
		'method'      : 'GET',
		'headers'     : {
			'authorization' : 'Token token="' + oamm_api.token + '"',
		},
	};

	return jQuery.ajax( pref_args );

 } // oamm_get_user_preferences()

 function oamm_save_user_preferences( user, preferences ) {

	if ( ! user || ! preferences ) {
		return false;
	}

	var settings = {
		'crossDomain' : true,
		'url'         : oamm_api.url + 'users/' + user + '/preferences',
		'method'      : 'PUT',
		'headers'     : {
			'authorization' : 'Token token="' + oamm_api.token + '"',
		},
		'data'        : {
			'data': preferences,
		},
		'xhrFields'   : {
			'withCredentials': true,
		},
		'dataType'    : 'json',
	};

	return jQuery.ajax( settings );

 } // oamm_save_user_preferences()
