jQuery( function( $ ) {

	// Show/hide
	function oamm_expand() {
		$( '.js_expand' ).on( 'click', function( e ) {
			e.stopPropagation();
			e.preventDefault();

			if ( $( this ).parent().hasClass( 'open' ) ) {
				$( this ).parent().removeClass( 'open' );
				$( this ).attr( 'aria-expanded', 'false' );
			} else {
				$( this ).parent().addClass( 'open' );
				$( this ).attr( 'aria-expanded', 'true' );
			}
		} );
	}

	oamm_expand();

} );
