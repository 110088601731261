jQuery( function( $ ) {

	/**
	 * Show/hide any dropdown navigation lists.
	 */
	function oamm_nav_dropdown() {
		$( '.nav-dropdown > a:not(.success-path-stage)' ).on( 'click', function( e ) {
			e.stopPropagation();
			e.preventDefault();

			if ( $( '.nav-dropdown > a:not(.success-path-stage)' ).hasClass( 'nav-dropdown-open' ) ) {
				$( '.nav-dropdown > a:not(.success-path-stage)' ).removeClass( 'nav-dropdown-open' );
			}

			if ( $( this ).hasClass( 'dropdown-open' ) ) {
				$( this ).removeClass( 'dropdown-open' );
			} else {
				$( this ).addClass( 'nav-dropdown-open' );
			}
		} );

		$( '.dropdown > a' ).on( 'click', function( e ) {
			e.stopPropagation();
			e.preventDefault();

			if ( $( '.dropdown > a' ).hasClass( 'dropdown-open' ) ) {
				$( '.dropdown > a' ).removeClass( 'dropdown-open' );
			}

			if ( $( this ).hasClass( 'dropdown-open' ) ) {
				$( this ).removeClass( 'dropdown-open' );
			} else {
				$( this ).addClass( 'dropdown-open' );
			}
		} );

		/**
		 * Close any dropdowns when a user clicks anywhere else on the page.
		 */
		$( document ).on( 'click', function( e ) {
			if ( $( e.target ).hasClass( 'js_addtomenu' ) || $( e.target ).hasClass( 'js_create-menu' ) ) {
				return;
			}

			if ( $( '.nav-dropdown > a:not(.success-path-stage)' ).hasClass( 'nav-dropdown-open' ) ) {
				$( '.nav-dropdown > a:not(.success-path-stage)' ).removeClass( 'nav-dropdown-open' );
			}

			if ( $( '.dropdown > a' ).hasClass( 'dropdown-open' ) ) {
				$( '.dropdown > a' ).removeClass( 'dropdown-open' );
			}
		} );
	} // oamm_nav_dropdown()

	oamm_nav_dropdown();

	var scroll_pos = 0;

	$( window ).on( 'scroll resize', function() {
		if ( oamm_viewport().width >= 545 ) {
			return;
		}

		var top = $( this ).scrollTop();

		if ( top > scroll_pos ) {
			$( '.nav__left' ).addClass( 'scroll-down' );
		} else {
			$( '.nav__left' ).removeClass( 'scroll-down' );
		}

		scroll_pos = top;
	} );

} );
