jQuery( function( $ ) {

	$( '.mb-tabs-nav-item a' ).on( 'click', function( e ) {

		e.preventDefault();

		var $container = $( this ).parents( '.mb-tabs' );

		$container.find( '.mb-tabs-nav-item a' ).removeClass( 'current' );
		$container.find( '.mb-tabs-content' ).removeClass( 'current' );

		var tab_id  = $( this ).attr( 'href' );
		$( this ).addClass( 'current' );
		$( tab_id ).addClass( 'current' );

	} );

} );
