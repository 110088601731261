jQuery( function( $ ) {

	if ( $( '.mb-fixed' ).length === 0 ) {
		return;
	}

	var $container = $( '.mb-fixed' );
	var $header    = $( '.mb-fixed-header' );

	$( window ).on( 'load scroll resize', function() {

		if ( oamm_viewport().width >= 768 ) {
			$header.css( 'margin-top', 'inherit' );
			$container.css( 'min-height', 'inherit' );
			return;
		}

		$header.css( 'margin-top', $header.outerWidth() );
		$container.css( 'min-height', $header.outerWidth() );

		var scroll_pos          = $( document ).scrollTop();
		var container_top       = $container.offset().top; // The top of the container.
		var in_the_container    = container_top - 15; // The top of the container at the point where the header should start to be fixed in the viewport.
		var below_the_container = container_top + $container.outerHeight() - $header.outerWidth() - 15; // The bottom of the container at the point the header should be placed at the bottom of the container.

		// If the user has scrolled below the container affix the header to the bottom of the container.
		if ( scroll_pos >= below_the_container ) {
			$header.addClass( 'fixed fixed_bottom' );
		// If the user has scrolled to the container but not below it affix the header to the top of the viewport.
		} else if ( scroll_pos >= in_the_container ) {
			$header.addClass( 'fixed' );
			$header.removeClass( 'fixed_bottom' );
		// Otherwise leave the header at the top of it's container.
		} else {
			$header.removeClass( 'fixed fixed_bottom' );
		}

	} );

} );
