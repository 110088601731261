jQuery( function( $ ) {

	var $live_preview = $( '.live-preview' );

	if ( $live_preview.length > 0 && $live_preview.val() !== '' ) {
		$( '.live-preview-text' ).html( $live_preview.val().toLowerCase().replace( /\s+/g, '' ) );
	}

	$live_preview.on( 'keyup', function( e ) {
		$( '.live-preview-text' ).html( $( this ).val().toLowerCase().replace( /\s+/g, '' ) );
	} );

} );
