jQuery( function( $ ) {

	$( '.js_resource-servings' ).on( 'click', function( e ) {
		e.preventDefault();

		var increment     = parseInt( $( this ).attr( 'data-increment' ) ),
		    current_count = parseInt( $( '.js_resource-servings-count' ).text() ),
		    new_count     = current_count + increment;

		if ( new_count > 0 ) {
			$( '.js_resource-servings-count' ).text( new_count );

			var user = parseInt( $( this ).attr( 'data-user' ) ),
			    menu = parseInt( $( this ).attr( 'data-menu' ) );

			if ( user && menu ) {
				oamm_get_user_preferences( user ).success( function( response ) {
					response.serving_sizes.menus[ menu ] = new_count;

					oamm_save_user_preferences( user, response ).fail( function( response ) {
						console.error( 'Failed to save the users serving size.', response );

						if ( typeof Rollbar === 'function' ) {
							Rollbar.error( 'Failed to save the users serving size.', response );
						}
					} );
				} ).fail( function( response ) {
					console.error( 'Failed to get the users preferences.', response );

					if ( typeof Rollbar === 'function' ) {
						Rollbar.error( 'Failed to get the users preferences.', response );
					}
				} );
			}
		}

		oamm_resource_download_link();
	} );

	$( '.js_resource-measurement a' ).on( 'click', function( e ) {
		e.preventDefault();

		$( '.js_resource-measurement a' ).removeClass( 'active' );
		$( this ).addClass( 'active' );

		oamm_resource_download_link();

		var measurement_type = $( this ).attr( 'data-measurement' ),
		    user             = parseInt( $( this ).attr( 'data-user' ) ),
		    menu             = parseInt( $( this ).attr( 'data-menu' ) );

		oamm_get_user_preferences( user ).success( function( response ) {
			if ( response.measurement_type ) {
				response.measurement_type.menus[ menu ] = measurement_type;
			} else {
				response.measurement_type = {};
				response.measurement_type.menus = {};
				response.measurement_type.menus[ menu ] = measurement_type;
			}

			oamm_save_user_preferences( user, response ).fail( function( response ) {
				console.error( 'Failed to save the users measurement type.', response );

				if ( typeof Rollbar === 'function' ) {
					Rollbar.error( 'Failed to save the users measurement type.', response );
				}
			} );
		} ).fail( function( response ) {
			console.error( 'Failed to get the users preferences.', response );

			if ( typeof Rollbar === 'function' ) {
				Rollbar.error( 'Failed to get the users preferences.', response );
			}
		} );
	} );

	$( '.js_resource-type' ).on( 'change', function() {
		oamm_resource_download_link();
	} );

	function oamm_resource_download_link() {

		var servings    = parseInt( $( '.js_resource-servings-count' ).text() ),
		    measurement = $( '.js_resource-measurement a.active' ).attr( 'data-measurement' ),
		    resource    = $( '.js_resource-type' ).val(),
		    slug        = $( '.js_resource-download' ).attr( 'data-slug' ),
		    url_parts   = window.location;

		$( '.js_resource-download' ).attr( 'href', url_parts.protocol + '//menu.' + url_parts.hostname + '/resources/' + slug + '/' + resource + '/?scaled_servings=' + servings + '&system=' + measurement );

	} // oamm_resource_download_link()

	function oamm_update_resource_links() {

		if ( $( '.menu-resources-links.menu-resources-links__logged-in' ).length > 0 ) {
			var query;

			$( '.menu-resources-form input' ).on( 'keyup blur change', function() {
				query = '?scaled_servings=' + $( '.menu-resources-form input[name="serving_size"]' ).val() + '&system=' + $( '.menu-resources-form input[name="measurement"]:checked' ).val();

				$( '.menu-resources-links a' ).each( function() {
					var resource = $( this ).attr( 'data-resource' );
					$( this ).attr( 'href', resource + query );
				} );
			} );
		}

	} // oamm_update_resource_links()

	oamm_update_resource_links();

	/*
	 * Show the pop up box to acknowledge my menu might be really difficult to cook
	 */
	var confirmed = false;

	if ( -1 !== document.cookie.indexOf( 'oamm_custom_menu_confirm=' ) ) {
		confirmed = true;
	}

	$( '.mb-menu-resources__custom .js_resource-download' ).on( 'click', function( e ) {
		e.preventDefault();

		var link = $( this ).attr( 'href' );

		if ( confirmed ) {
			window.open( link, '_blank' );
			return;
		}

		$( '.oamm-lightbox' ).show();
		$( '.js_mb-confirm' ).show();
	} );

	$( '.js_mb-confirm-checkbox' ).on( 'click', function( e ) {
		if ( $( this ).is( ':checked' ) ) {
			var now  = new Date(),
			    time = now.getTime();

			time += 1000 * 3600 * 24 * 30; // 1 second * 1 hour * 1 day * 30 days
			now.setTime( time );

			confirmed = true;
			document.cookie = 'oamm_custom_menu_confirm=true; path=/; expires=' + now.toUTCString();

			if ( typeof ga === 'function' ) {
				ga( 'send', 'event', 'Custom Menu', 'Click', 'Acknowledge' );
			}

			$( '.oamm-lightbox' ).hide();
			$( '.js_mb-confirm' ).hide();
		}
	} );

} );
