/**
 * Functions related to saving and removing recipes from favorites.
 */

 jQuery( function( $ ) {

 	/**
 	 * Toggles the favorites heart icon
 	 *
 	 * @param $icon The jQuery element to toggle.
 	 */
	function fav_toggle_icon( $icon ) {
		$icon.toggleClass( 'favorited' );
		$icon.children( 'i' ).toggleClass( 'fas' );
		$icon.children( 'i' ).toggleClass( 'far' );
	} // fav_toggle_icon()

	$( document ).on( 'click', '.js_favorite', function( e ) {
		e.preventDefault();

		var $fav_icon  = $( this ),
			recipe_id  = $fav_icon.attr( 'data-recipe-id' ),
			req_method = 'POST',
			api        = oamm_get_api();

		$( '.js_favorite-text' ).text( 'Favorited' );

		// If the recipe is already favorited then the user wants to remove the recipe from favorites
		if ( $fav_icon.hasClass( 'favorited' ) ) {
			req_method = 'DELETE';

			if ( $fav_icon.hasClass( 'col-buttons-btn' ) ) {
				$fav_icon.text( 'Removing...' ).attr( 'disabled', true );
			}

			$( '.js_favorite-text' ).text( 'Favorite' );
			oamm_check_for_checkmarks();
		}

		// Make sure they have less than 200 favorites
		if ( 'POST' === req_method && $fav_icon.attr( 'data-favorites-count' ) > 200 ) {
			$( 'body' ).append( '<div class="notification-bar"></div>' );
			$( '.notification-bar' ).addClass( 'error' );
			$( '.notification-bar' ).text( 'You\'ve reached the limit! You have over 200 favorite recipes. That\'s a lot of recipes! First, remove some recipes from your favorites to add more.' );
			$( '.notification-bar' ).delay( 4000 ).fadeOut( 500 );
			$( '.js_favorite-text' ).text( 'Favorite' );
			return;
		}

		fav_toggle_icon( $fav_icon );

		var req_args = {
			'url': api.url + 'recipes/' + recipe_id + '/favorites',
			'method': req_method,
			'crossDomain': true,
			'headers': {
				'authorization': 'Token token="' + api.token + '"',
			},
			'xhrFields': {
				'withCredentials': true,
			},
		};

		// Make the request and reset the icon if the request fails
		$.ajax( req_args )
			.done( function( response ) {
				// Make sure we actually have a successful response
				if ( response.favorite_id ) {
					if ( 'DELETE' === req_method ) {
						$( 'tr#' + recipe_id ).remove();

						if ( $( '.mb-recipes-list__favorites' ).length ) {
							$( 'li#recipe-' + recipe_id ).fadeOut( 250, function() {
								$( this ).remove();
							} );
						}
					}

					if ( typeof ga === 'function' ) {
						if ( 'DELETE' === req_method ) {
							ga( 'send', 'event', 'Favorites', 'Remove', recipe_id );
						} else {
							ga( 'send', 'event', 'Favorites', 'Add', recipe_id );
						}
					}

					if ( typeof Intercom === 'function' ) {
						if ( 'DELETE' === req_method ) {
							Intercom( 'trackEvent', 'Favorites: Remove' );
						} else {
							Intercom( 'trackEvent', 'Favorites: Add' );
						}
					}
				} else {
					fav_toggle_icon( $fav_icon );

					if ( $fav_icon.hasClass( 'col-buttons-btn' ) ) {
						$fav_icon.text( 'Remove' ).attr( 'disabled', false );
					}
				}
			} )
			.fail( function( response ) {
				fav_toggle_icon( $fav_icon );

				if ( $fav_icon.hasClass( 'col-buttons-btn' ) ) {
					$fav_icon.text( 'Remove' ).attr( 'disabled', false );
				}

				console.log( req_args );
				console.log( response );

				if ( typeof Rollbar === 'function' ) {
					Rollbar.error( 'There was an error favoriting this recipe.', req_args );
				}
			} );
	} );

	/**
	 * Toggle all checkboxes when the main checkbox is clicked.
	 */
	$( document ).on( 'click', '.js_select-all', function( e ) {
		if ( $( this ).is( ':checked' ) ) {
			$( 'input[type="checkbox"]' ).prop( 'checked', true );
		} else {
			$( 'input[type="checkbox"]' ).prop( 'checked', false );
		}

		oamm_check_for_checkmarks();
	} );

	$( 'input[type="checkbox"]' ).on( 'change', function( e ) {
		oamm_check_for_checkmarks();
	} );

	/**
	 * Check if any recipes have been selected.
	 */
	function oamm_check_for_checkmarks() {
		if ( $( 'input[type="checkbox"]' ).is( ':checked' ) ) {
			$( '.btn-new-menu' ).removeClass( 'disabled' );
		} else {
			$( '.btn-new-menu' ).addClass( 'disabled' );
		}
	} // oamm_check_for_checkmarks()

	if ( typeof List === 'function' ) {
		var fav_search_options = {
		  valueNames: [ 'js_name', 'js_meal-type', 'js_cook-type', 'js_author' ]
		};

		var fav_search = new List( 'js_favorites-search', fav_search_options );
	}

} );
