jQuery( function( $ ) {

	/**
	 * Sets up Google event tracking on all buttons.
	 */
	$( '.block .btn' ).on( 'click', function() {
		// Check that the Google Analytics script has loaded.
		if ( typeof ga === 'function' ) {
			var action = $( this ).text();

			if ( ! action || '' === action ) {
				action = $( this ).val();
			}

			ga( 'send', 'event', {
				eventCategory: 'Button Click',
				eventAction: action,
				eventLabel: window.location,
			} );
		}
	} );

} );
