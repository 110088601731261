jQuery( function( $ ) {

	//var waterfall_count = 0;

	/**
	 * Adds a waterfall effect to items within a container.
	 *
	 * @param container The container of the items we want to waterfall.
	 */
	/*window.waterfall = function( container ) {
		if ( typeof container === 'string' ) {
			container = document.querySelector( container );
		}

		container.style.position = 'relative';

		function hasClass( ele, cls ) {
			return ele.className.match( new RegExp( '(\\s|^)' + cls + '(\\s|$)' ) );
		} // hasClass()

		function removeClass( ele, cls ) {
			if ( hasClass( ele, cls ) ) {
				var reg = new RegExp( '(\\s|^)' + cls + '(\\s|$)' );
				ele.className = ele.className.replace( reg, ' ' );
			}
		} // removeClass()

		var boundary = [];
		var els = [].map.call( container.children, function( el ) {
			if ( hasClass( el, 'card' ) ) {
				el.style.position = 'absolute';
				removeClass( el, 'loading' );
				return el;
			}
		} );
		var els_count = els.length;

		function style( el ) {
			if ( ! el ) {
				return false;
			}

			return window.getComputedStyle( el );
		} // style()

		function margin( name, el ) { return parseFloat( style( el )[ 'margin' + name ] ) || 0; }

		function px( n ) { return n + 'px'; }
		function y( el ) { return parseFloat( el.style.top ); }
		function x( el ) { return parseFloat( el.style.left ); }
		function width( el ) { return parseFloat( style( el ).width); }
		function height( el ) { return parseFloat( style( el ).height); }
		function bottom( el ) { return y( el ) + height( el ) + margin( 'Bottom', el ); }
		function right( el ) { return x( el ) + width( el ) + margin( 'Right', el ); }

		function sort( l ) {
			l = l.sort( function( a, b ) {
				var bottom_diff = bottom( b ) - bottom( a );
				return bottom_diff || x( b ) - x( a );
			} );
		} // sort()

		// Deal with the first element.
		if ( els && els_count ) {
			els[0].style.top  = '0px';
			els[0].style.left = px( margin( 'Left', els[0] ) );

			boundary.push( els[0] );
		}

		// Deal with the first line.
		for ( var i = 1; i < els_count; i++ ) {
			var prev = els[i - 1],
				elem = els[i],
				thereIsSpace = right( prev ) + width( elem ) <= width( container );

			if ( ! thereIsSpace ) break;
				elem.style.top = prev.style.top;

			elem.style.left = px( right( prev ) + margin( 'Left', elem ) );
			boundary.push( elem );
		}

		// Place the rest of the elements at the bottom of the smallest column.
		for ( ; i < els_count; i++ ) {
			sort( boundary );

			var element  = els[i],
				minEl = boundary.pop();

			element.style.top  = px( bottom( minEl ) + margin( 'Top', element ) ) ;
			element.style.left = px( x( minEl ) );

			boundary.push( element );
		}

		sort( boundary );
		var maxEl = boundary[0];
		container.style.height = px( bottom( maxEl ) + margin( 'Bottom', maxEl ) );
		removeClass( container, 'loading' );
		waterfall_count++;
	}; // waterfall()*/

	/**
	 * Show the cards if there are any on the page.
	 */
	/*if ( $( '.cards-wrapper' ).length > 0 && $( '.cards-wrapper .card' ).length > 0 ) {
		// Run once all images have loaded.
		$( '.cards-wrapper' ).imagesLoaded().always( function() {
			if ( waterfall_count === 0 ) {
				waterfall( '.cards-wrapper' );
				$( '.cards-wrapper, .card' ).removeClass( 'loading' );
			}
		} );

		// If that didn't work run on window load.
		$( window ).on( 'load', function() {
			if ( waterfall_count === 0 ) {
				waterfall( '.cards-wrapper' );
				$( '.cards-wrapper, .card' ).removeClass( 'loading' );
			}
		} );

		// Run again if the window is resized.
		$( window ).on( 'resize', function() {
			waterfall( '.cards-wrapper' );
		} );

		// If watefall is taking a long time to load display it anyway after 7 seconds.
		setTimeout( function() {
			if ( $( '.card' ).hasClass( 'loading' ) ) {
				$( '.cards-wrapper, .card' ).removeClass( 'loading' );
			}
		}, 7000 );
	}*/

} );
