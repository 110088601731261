jQuery( function( $ ) {

	$( '.btn__success-path' ).on( 'click', function( e ) {
		e.preventDefault();

		var $this = $( this ),
		    stage = $this.attr( 'data-stage' ),
		    href  = $this.attr( 'href' ),
		    text  = $this.html(),
		    user  = window.intercomSettings ? window.intercomSettings.user_id : null;

		$this.html( '<i class="fas fa-spinner fa-pulse"></i> Let\'s go!' ).addClass( 'btn__teal' );

		if ( stage && user ) {
			oamm_get_user_preferences( user ).success( function( response ) {
				response.user_stage = stage;

				oamm_save_user_preferences( user, response ).done( function( response ) {
					Intercom( 'update', {
						success_path: stage
					} );

					$( '.success-path-stage' ).text( stage );

					if ( href.includes( 'http' ) ) {
						window.location = href;
						return;
					}

					window.location = window.location.href + href;
					$this.html( text ).removeClass( 'btn__teal' );
					return;
				} ).fail( function( response ) {
					$this.html( text ).removeClass( 'btn__teal' );

					oamm_show_notification( 'Failed to save your new Success Path stage.', 'error' );
					console.error( 'Failed to save the users Success Path stage.', response );

					if ( typeof Rollbar === 'function' ) {
						Rollbar.error( 'Failed to save the users Success Path stage.', response );
					}
				} );
			} ).fail( function( response ) {
				$this.html( text ).removeClass( 'btn__teal' );

				oamm_show_notification( 'Failed to get your preferences.', 'error' );
				console.error( 'Failed to get the users preferences.', response );

				if ( typeof Rollbar === 'function' ) {
					Rollbar.error( 'Failed to get the users preferences.', response );
				}
			} );
		} else {
			if ( href.includes( 'http' ) ) {
				window.location = href;
				return;
			}

			window.location = window.location.href + href;
			$this.html( text ).removeClass( 'btn__teal' );
			return;
		}
	} );

} );